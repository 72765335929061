<template>
  <div id="login">
    <h2 class="text-center color-4">欢迎来到智慧财务分析平台</h2>
    <form action="" class="mt-5">
      <div class="row color-w mb-2 mt-3">
        <el-input
          placeholder="请输入手机号"
          type="text"
          prefix-icon="el-icon-user"
          v-model="username"
          id="username"
        ></el-input>
      </div>
      <div class="row color-w mt-3">
        <el-input
          id="password"
          type="password"
          placeholder="请输入密码"
          prefix-icon="el-icon-lock"
          v-model="password"
        ></el-input>
      </div>
    </form>
    <div class="row login_link_item justify-content-between mt-3">
      <router-link
        :to="{ path: `/backPassWord`, query: { phone: username } }"
        class="color-9 fz-14 forget"
        >忘记密码？</router-link
      >
      <router-link to="/register" class="color-9 fz-14"
        >还没有账号？快去注册</router-link
      >
    </div>
    <el-button
      @click="login"
      type="primary"
      class="mt-3 mb-3"
      style="width: 100%"
      >登 录</el-button
    >
    <a
      href="#/agreement"
      target="_blank"
      rel="noopener"
      class="forget color-5d fz-14"
      >点击登录即代表您已阅读并同意<span style="color: #1989fa"
        >《用户协议》</span
      >
    </a>
  </div>
</template>

<script>
import { CheckUserPower, userBasicInfo } from "@/api.js";
import * as sesstion from "../../store/localStorage";
let flag = false;
export default {
  data() {
    return {
      username: "",
      password: "",
    };
  },
  components: {},
  mounted() {
    // 获取页面中携带的临时token
    // this.getUrlParams(window.location.href)
  },
  methods: {
    // getUrlParams(url) {
    //   // 通过 ? 分割获取后面的参数字符串
    //   let urlStr = url.split("?")[1];
    //   // 创建空对象存储参数
    //   let obj = {};
    //   // 再通过 & 将每一个参数单独分割出来
    //   let paramsArr = urlStr.split("&");
    //   for (let i = 0, len = paramsArr.length; i < len; i++) {
    //     // 再通过 = 将每一个参数分割为 key:value 的形式
    //     let arr = paramsArr[i].split("=");
    //     obj[arr[0]] = arr[1];
    //   }
    //   this.getToken(obj.t);
    // },
    getToken(L_token) {
      this.$http
        .get(window.BASE_URL + "/uc/no_login/company/?t=" + L_token)
        .then((res) => {
          var userData = {
            auth_token: res.data.token,
          };
          this.$store.dispatch("addLoginUser", userData);
          // 企业信息存入本地
          sesstion.setLocalData("company", res.data.company);
          setTimeout(() => {
            this.getUserInfo(userData.auth_token);
            sessionStorage.token = userData.auth_token;
          }, 500);
        });
    },
    getUserInfo(access) {
      userBasicInfo().then(({ data }) => {
        var userData = {
          ...data,
          auth_token: access,
        };
        flag = false;
        sesstion.setLocalData("user_permission", data.user_permission);
        this.$store.dispatch("addLoginUser", userData);

        // this.$router.push("database/data-manager");
        setTimeout(() => {
          this.$router.push({ name: "ListDebt" });
        }, 500);
      });
    },
    login() {
      if (flag) return false;
      flag = true;
      var param = {
        username: this.username,
        password: this.password,
      };
      this.$http.post(window.BASE_URL + "/auth/token/", param).then(
        (res) => {
          this.$message({
            message: "登录成功",
            type: "success",
          });
          var userData = {
            auth_token: res.data.access,
          };
          this.$store.dispatch("addLoginUser", userData);

          setTimeout(() => {
            this.checkUer();
            this.getUserInfo(userData.auth_token);
            sessionStorage.token = userData.auth_token;
          }, 500);
        },
        (res) => {
          var res = res.response;
          flag = false;
          if (res.data.detail) {
            this.$store.state.sendCode.errorTip = res.data.detail;
            this.$message.error(res.data.detail);
          } else if (res.data.non_field_errors != null) {
            this.$store.state.sendCode.errorTip = "手机号或密码错误";
            this.$message.error("手机号或密码错误");
          } else if (res.data.username != null) {
            this.$store.state.sendCode.errorTip = res.data.username[0];
            this.$message.error(res.data.username[0]);
          } else if (res.data.password != null) {
            this.$store.state.sendCode.errorTip = res.data.password[0];
            this.$message.error(res.data.password[0]);
          }
        }
      );
    },
    checkUer() {
      CheckUserPower().then((res) => {
        const { data } = res;
        if (data.status == 200) {
          this.$store.commit("setPowerUser", data.msg);
        } else {
          this.$Message.warning(data.msg);
        }
      });
    },
    getCookie(name) {
      var value = "; " + document.cookie;
      var parts = value.split("; " + name + "=");
      if (parts.length === 2) return parts.pop().split(";").shift();
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.state.routerGet = from.name;
    });
  },

  created() {
    // 清空错误提示
    this.$store.state.sendCode.errorTip = "";
    setTimeout(() => {
      this.$store.commit("setShowNav", false);
    }, 0);
  },
};
</script>

<style scoped>
input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #999;
  font-size: 0.8rem;
}

#login {
  width: 550px;
  border-radius: 10px;
  background: #fff;
  padding: 70px;
  margin: 0 auto;
}
</style>
